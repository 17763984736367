<template>
  <div>
    <x-select-periodo-fiscal @input="onChangePeriodoFiscal" class="mb-3" modulo="Prestaciones"/>

    <div v-if="isListAvailable">
      <table class="table table-striped table-hover table-responsive table-sm table-bordered">
        <thead class="thead-light">
          <tr>
            <th
              v-for="item in fields"
              :key="item.key"
              scope="col"
              style="font-size: 12px; text-align: center;"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <draggable v-model="getList" tag="tbody">
          <tr v-for="(item, index) in getList" :key="item.nombre">
            <!-- Clave -->
            <td scope="row">
              <div class="td__cell">
                {{ item.clave }}
              </div>
            </td>

            <!-- Nombre -->
            <td>
              <div class="td__cell">
                {{ item.nombre }}
              </div>
            </td>

            <!-- Periodo fiscal -->
            <td>
              <div class="td__cell">
                <b-badge>
                  {{ item.periodo_fiscal.nombre }}
                </b-badge>
              </div>
            </td>

            <!-- Partida -->
            <td>
              <div class="td__cell">
                {{ item.partida.nombre }}
              </div>
            </td>

            <!-- Estatus -->
            <td>
              <div class="td__cell">
                <b-badge :variant="$getStatusVariant(item.estatus)">
                  {{ $getStatusText(item.estatus) }}
                </b-badge>
              </div>
            </td>

            <!-- Mes pago -->
            <td style="max-width: 10rem">
              <div style="min-height: 80px">
                <b-badge
                  v-for="item in item.mes_pago.split(',')"
                  :key="item.id"
                  class="mr-1"
                  v-text="item"
                />
              </div>
            </td>

            <!-- Acciones -->
            <td class="h-100">
              <div class="td__cell align-self-center h-100">
                <u-d-controls
                  :id="item.id"
                  editPath="prestacionesModule/getById"
                  deletePath="prestacionesModule/deleteById"
                  :editPermissions="['editar prestaciones']"
                  :deletePermissions="['eliminar prestaciones']"
                  @on-got-item="$up"
                ></u-d-controls>
              </div>
            </td>

            <td style="min-width: 2rem;">
              <div class="td__cell">
                <b-badge pill variant="primary">
                  <strong>{{ index + 1 }}</strong>
                </b-badge>
              </div>
            </td>

            <td style="min-width: 2rem;">
              <div class="td__cell">
                <i class="fas fa-arrows-alt-v"></i>
              </div>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
    <loading v-else class="py-10"></loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'PrestacionesList',

  components: {
    draggable
  },

  data () {
    return {
      periodo_fiscal_id: null,

      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'periodo_fiscal', label: 'Periodo fiscal' },
        { key: 'partida', label: 'Partida' },
        { key: 'estatus', label: 'Estatus' },
        { key: 'mes_pago', label: 'Tipo de pago' },
        { key: 'acciones', label: 'Acciones' },
        { key: 'prioridad', label: 'Prioridad' },
        {}
      ]
    }
  },

  computed: {
    ...mapGetters('prestacionesModule', ['isListAvailable']),

    getList: {
      get () {
        return this.$store.state.prestacionesModule.list
      },

      async set (value) {
        const { error, message } = await this.updatePriority(value)

        this.$notify({ error, message }, 'Listado de prestaciones')
      }
    }
  },

  methods: {
    ...mapActions('prestacionesModule', ['updatePriority']),

    emitOnInput () {
      this.$emit('input', this.periodo_fiscal_id)
    },

    async onChangePeriodoFiscal (id) {
      this.periodo_fiscal_id = id

      this.emitOnInput()
    }
  }
}
</script>

<style lang="scss">
td:hover {
  cursor: grab;
}
.td__cell {
  width: 100%;
  height: 100%;
  min-height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-overflow: ellipsis;
}
</style>
