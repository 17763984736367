var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-icon mb-4"},[_c('div',{staticClass:"row no-gutters"},[_vm._m(0),_c('div',{staticClass:"col"},[_c('b-overlay',{attrs:{"show":_vm.isCreatingResource || _vm.isUpdatingResource},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('loading',{attrs:{"message":"Procesando, por favor espere..."}})],1)]},proxy:true}])},[_c('div',{staticClass:"card-body py-4"},[_c('h3',{staticClass:"card-title"},[_c('span',{class:_vm.isEditingResource ? 'text-danger' : ''},[_vm._v(_vm._s(_vm.titleForm))])]),_c('div',{staticClass:"card-text"},[_c('FormulateForm',{ref:"prestacionCatalogoForm",on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
var isLoading = ref.isLoading;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('x-form-group',{attrs:{"title":"Clave","required":""}},[_c('FormulateInput',{attrs:{"type":"text","disabled":isLoading,"placeholder":"Ingresa la clave de prestación","validation":"bail|required|max:100,length","validation-messages":{
                        required: 'La clave es requerida',
                        max: 'La longitud máxima es de 100 caracteres'
                      }},model:{value:(_vm.formValues.clave),callback:function ($$v) {_vm.$set(_vm.formValues, "clave", $$v)},expression:"formValues.clave"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('x-form-group',{attrs:{"title":"Nombre","required":""}},[_c('FormulateInput',{attrs:{"disabled":isLoading,"placeholder":"Ingresa el nombre de la prestación","validation":"bail|required|max:255,length","validation-messages":{
                        required: 'El nombre de la prestación es requerido',
                        max: 'La longitud máxima es de 255 caractéres'
                      }},model:{value:(_vm.formValues.nombre),callback:function ($$v) {_vm.$set(_vm.formValues, "nombre", $$v)},expression:"formValues.nombre"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('FormulateInput',{attrs:{"name":"periodo_fiscal","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
                      required: 'El periodo fiscal es requerido'
                    }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Periodo fiscal","required":""}},[_c('check-authorization',{attrs:{"requiresAuthorizations":['listar periodos fiscales'],"show-alert":false,"no-slots":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var authorized = ref.authorized;
                    var message = ref.message;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",modifiers:{"top":true}}],attrs:{"title":authorized ? '' : message}},[_c('x-select',{attrs:{"dispatchPath":"fiscalPeriodsModule/getFiscalPeriods","getterListPath":"fiscalPeriodsModule/getAllFiscalPeriods","formatListWith":['id', 'nombre'],"defaultText":"-- Selecciona un periodo fiscal --","no-prepend":"","no-validate":"","disabled":!authorized},model:{value:(_vm.formValues.periodo_fiscal_id),callback:function ($$v) {_vm.$set(_vm.formValues, "periodo_fiscal_id", $$v)},expression:"formValues.periodo_fiscal_id"}})],1)]}}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.periodo_fiscal_id),callback:function ($$v) {_vm.$set(_vm.formValues, "periodo_fiscal_id", $$v)},expression:"formValues.periodo_fiscal_id"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('FormulateInput',{attrs:{"name":"partida","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
                      required: 'La partida es requerida'
                    }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Partida","required":""}},[_c('x-select',{ref:"xselectpartidas",attrs:{"dispatchPath":null,"getterListPath":"partidaModule/getPartidas","formatListWith":['id', 'clave_nombre'],"defaultText":"-- Selecciona una partida --","no-prepend":"","no-validate":""},model:{value:(_vm.formValues.partida_id),callback:function ($$v) {_vm.$set(_vm.formValues, "partida_id", $$v)},expression:"formValues.partida_id"}})],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.partida_id),callback:function ($$v) {_vm.$set(_vm.formValues, "partida_id", $$v)},expression:"formValues.partida_id"}})],1)],1),_c('FormulateInput',{attrs:{"name":"mes_pago","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
                  required: 'El pago del mes es requerido'
                }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Mes pago","required":""}},[_c('x-calendar',{model:{value:(_vm.formValues.mes_pago),callback:function ($$v) {_vm.$set(_vm.formValues, "mes_pago", $$v)},expression:"formValues.mes_pago"}})],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.mes_pago),callback:function ($$v) {_vm.$set(_vm.formValues, "mes_pago", $$v)},expression:"formValues.mes_pago"}}),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"my-2 user-select-none",attrs:{"switch":"","inline":""},model:{value:(_vm.formValues.estatus),callback:function ($$v) {_vm.$set(_vm.formValues, "estatus", $$v)},expression:"formValues.estatus"}},[_vm._v(" Estado ")])],1),_c('x-form-footer-buttons',{attrs:{"disabled":hasErrors || _vm.isCreatingResource || _vm.isUpdatingResource,"loading":_vm.isCreatingResource || _vm.isUpdatingResource,"isEditing":_vm.isEditingResource,"isCreating":!_vm.isEditingResource},on:{"on-cancel":_vm.resetForm,"on-click-create":_vm.submit,"on-click-update":_vm.submit}})]}}])})],1)])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-none\n          d-md-flex\n          col-auto\n          card-icon-aside\n          bg-primary\n          text-white"},[_c('i',{attrs:{"data-feather":"award"}})])}]

export { render, staticRenderFns }